<template>
  <el-container class="content-center-middle">
    <el-card>
      <el-row class="content-center-middle" :gutter="10">
        <!-- <el-col :xs="24" :sm="12" >
          <img class="image" src="../../assets/poster.png">
        </el-col> -->
        <el-col :xs="24" :sm="18" :md="14" :lg="10">
          <div :class="isExpired">
            <el-icon>
              <i v-if="type == 'C'" class="bi bi-check2-circle"></i>
              <i v-else class="bi bi-exclamation-triangle"></i>
            </el-icon>
          </div>
          <p>{{ $t(`personal.forbidden.${type}`) }}</p>
          <div v-if="type !== 'I' && type !== 'default'">
            <p style="margin-bottom:0px">{{ $t("personal.forbidden.info") }}</p>
            <ul class="buttons">
              <li @click="goTo('PersonalAppInfo')">
                <table width="100%">
                  <tbody>
                    <tr>
                      <td width="20%" align="left">
                        <el-icon>
                          <i class="bi bi-info-circle"></i>
                        </el-icon>

                      </td>
                      <td width="80%" align="left" class="padding-left-20">
                        <h4>{{ $t("personal.start.buttons.info") }}</h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- <el-icon>
                  <font-awesome-icon icon="info-circle" />
                </el-icon>
                <h4>{{ $t("personal.start.buttons.info") }}</h4> -->
              </li>
              <li @click="openFAQ">
                <table width="100%">
                  <tbody>
                    <tr>
                      <td width="20%" align="left">
                        <el-icon>
                          <i class="bi bi-question-circle"></i>
                        </el-icon>

                      </td>
                      <td width="80%" align="left" class="padding-left-20">
                        <h4>{{ $t("personal.start.buttons.faq") }}</h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- <el-icon>
                  <font-awesome-icon :icon="['far', 'question-circle']" />
                </el-icon>
                <h4>{{ $t("personal.start.buttons.faq") }}</h4> -->
              </li>
            </ul>
          </div>

          <el-row style="text-align: left;">
            <el-col :span="24">
              <el-alert class="browserSupport" type="info" :closable="false">
                <BrowserSupport type="personal" />
              </el-alert>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <!-- <p v-text="$t('personal.complete.appNo', {appNo: appNo})" /> -->

    </el-card>
  </el-container>
</template>

<style scoped>
ul.buttons {
  background-color: #ffffff;
}

.content-center-middle {
  display: flex !important;
}

@media screen and (max-width: 576px) {
  .image {
    display: none;
  }

  .el-card {
    text-align: center;
    font-size: 18px;
  }
}

@media screen and (min-width: 577px) {
  .el-card {
    text-align: center;
    font-size: 20px;
  }
}

.warning .el-icon {
  font-size: 5em;
  color: #ffc107;
}

.success .el-icon {
  font-size: 5em;
  color: #67c23a;
}

.el-container {
  display: block;
}

.el-card {
  width: 90%;
}

.image {
  width: 100%;
}

@media screen and (max-width: 709px) {
  .el-container {
    min-height: calc(100vh - 137px);
  }
}

@media screen and (min-width: 710px) {
  .el-container {
    min-height: calc(100vh - 120px);
  }
}
</style>

<script>
import "@/style/common.css";
import { goTo, openLink } from "../../libs/common";
import BrowserSupport from "@/components/BrowserSupport.vue";

export default {
  name: "forbidden",
  components: {
    BrowserSupport
  },
  data() {
    return {
      type: "default"
    }
  },
  methods: {
    goTo(name) {
      goTo(name)
    },
    openFAQ() {
      openLink("personal.faq");
    }
  },
  created() {
    let query = this.$router.currentRoute.value.query;
    if (Object.prototype.hasOwnProperty.call(query, "type")) {
      this.type = query.type;
    }
  },
  computed: {
    isExpired() {
      return this.type == "C" ? "success" : "warning"
    }
  }
}
</script>