<template>
  <h4 class='nomargin'>
    {{ $t(`browserSupport.${this.type}.title`) }}
  </h4>
  
  <ul class='nomargin'>
    <li v-for="content in $tm(`browserSupport.${this.type}.contents`)"
      :key="content"
      v-text="content"
  />
  </ul>
</template>

<script>
export default {
  name: "BrowserSupport",
  props: {
    type: String
  }
}
</script>

<style scoped>
h4,
ul {
  color: #888888;
}
ul {
  padding-inline-start: 20px;
}
</style>
